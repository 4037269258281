import logo from 'Assets/upload/images/logo.png'
import { FC } from 'react'

const Header: FC = () => (
  <header className="header">
    <div className="container">
      <div className="header__content">
        <div className="header__logo" style={{ zIndex: 999 }}>
          <img src={logo} alt={''} />
        </div>
        <div className="header__contacts">
          <a href="tel:+73512110020">211-00-20</a>
          <span>
            Появились вопросы?
            <br />
            Звоните!
          </span>
        </div>
      </div>
    </div>
  </header>
)

export default Header
