import { FC } from 'react'
import intro from 'Assets/upload/images/intro.png'
import intro2x from 'Assets/upload/images/intro@2x.png'

interface Props {
  clientName: string
}

const HomeSection: FC<Props> = ({ clientName }) => (
  <section className="section section_green home__section section_big-padding">
    <div className="intro">
      <div className="container intro__container">
        <div className="intro__title" id="clientName">
          {clientName} ,<br />
          это доставка Сели-Съели
        </div>
        <p className="intro__text">
          На&nbsp;этой странице вы&nbsp;можете отследить статус заказа, оценить
          нашу работу и&nbsp;прочее.
        </p>
        <p className="intro__text">Приятного аппетита!</p>
        <img className="intro__img" src={intro} srcSet={intro2x} alt={''} />
      </div>
    </div>
  </section>
)

export default HomeSection
